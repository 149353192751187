import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Context } from '../../../Context/AuthContext';
import { HubContext } from '../../../Context/HubContext.js';
import { Request } from '../../../scripts/Request';
import { WaitObject } from '../../../scripts/Wait';
import { CacheDB } from '../../../scripts/CacheDB';
import InfiniteScroll from "react-infinite-scroll-component";
import { Currency } from '../../../scripts/StringUtils';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment/moment';
import ReactGA from 'react-ga';
import './Manutencoes.css';

import Geral from './Geral';
import Lavagens from './Lavagens';
import Danos from './Danos';



export default function Manutencoes() {
    const component_name = "admin/manutencoes";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const { Hub } = useContext(HubContext);
    const [pageLoading, setPageLoading] = useState(false);
    const [info, setInfo] = useState({});
    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);



    let { Id, Tag, Outros } = useParams();


    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);

        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                WaitObject(user.nome).then(() => {
                    Init();
                });
            }
        }, 200);


        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    
                } catch(e) {}
            }
        }
    },[]);



    useEffect(() => {
        if(loading || pageLoading) {
            window.w_loadObj("body")
        } else {
            window.w_unloadAll();
        }
    }, [loading, pageLoading]);
    //#endregion Effects



    //#region Init
    const Init = async() => {
        Hub.Exec("HEADER_TITLE", "Manutenções - " + Tag);

        await CacheDB.Initialize().then(async(result) => {
            console.log("Cache Initialized");
           
        }).catch((err) => { 
            console.log(err);
        })

        await CacheDB.Set("equipamentos_keep", 1);
        
     }
    //#endregion Init



    //#region Handlers
    const HandleSelect = (info) => {
        setInfo(info);
    }
    //#endregion Handlers


    




    return (
        <>
            <div className="page-content header-clear-medium Manutencoes">
                <div className="tabs tabs-cards" id="tab-group-5">
                    <div className="tab-controls">
                        <a className="font-13" data-bs-toggle="collapse" href="#tab-13" aria-expanded="true">Geral</a>
                        <a className="font-13" data-bs-toggle="collapse" href="#tab-14" aria-expanded="false">Lavagens</a>
                        <a className="font-13" data-bs-toggle="collapse" href="#tab-15" aria-expanded="false">Danos</a>
                    </div>
                    
                    <div className="card card-style">
                        <div className="collapse show" id="tab-13" data-bs-parent="#tab-group-5">
                            <Geral Id={Id} Tag={Tag} Outros={Outros} OnSelect={HandleSelect}/>
                        </div>
                        <div className="collapse" id="tab-14" data-bs-parent="#tab-group-5">
                            <Lavagens Id={Id} Tag={Tag} OnSelect={HandleSelect}/>
                        </div>
                        <div className="collapse" id="tab-15" data-bs-parent="#tab-group-5">
                            <Danos Id={Id} Tag={Tag} OnSelect={HandleSelect}/>
                        </div>
                    </div>
                   
                </div>
            </div>

            <div className="offcanvas offcanvas-bottom rounded-m offcanvas-detached offcanvas-99" id="menu-information">
                <div className="gradient-blue px-3 py-3">
                    <div className="d-flex mt-1">
                        <div className="align-self-center">
                            <i className="bi bi-info-circle-fill font-22 pe-2 scale-box color-white"></i>
                        </div>
                        <div className="align-self-center">
                            <h1 className="font-800 color-white mb-0">{info.tag}</h1>
                        </div>
                    </div>
                    <p className="color-white opacity-80 pt-2">
                        {info.info}
                    </p>
                    <a href="#" data-bs-dismiss="offcanvas"
                        className="default-link btn btn-full btn-s bg-white color-black">Fechar</a>
                </div>
            </div>
        </>
    )
}