import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Context } from '../../../Context/AuthContext';
import { HubContext } from '../../../Context/HubContext.js';
import { Req, Request } from '../../../scripts/Request';
import { WaitObject } from '../../../scripts/Wait';
import { CacheDB } from '../../../scripts/CacheDB';
import InfiniteScroll from "react-infinite-scroll-component";
import { Currency } from '../../../scripts/StringUtils';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment/moment';
import ReactGA from 'react-ga';
import './Manutencoes.css';


const tipos = [
    "",
    "Evaporadora",
    "Condensadora",
];



export default function Danos(props) {
    const component_name = "admin/manutencoes_danos";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const { Hub } = useContext(HubContext);
    const [pageLoading, setPageLoading] = useState(false);
    const [list, setList] = useState([]);


    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);

    let { Id, Tag } = useParams();


    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);

        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                WaitObject(user.nome).then(() => {
                    Init();
                });
            }
        }, 200);


        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    
                } catch(e) {}
            }
        }
    },[]);



    useEffect(() => {
        if(loading || pageLoading) {
            window.w_loadObj("body")
        } else {
            window.w_unloadAll();
        }
    }, [loading, pageLoading]);
    //#endregion Effects



    //#region Init
    const Init = async() => {
        Hub.Exec("HEADER_TITLE", "Manutenções - " + props.Tag);

        LoadList();
     }
    //#endregion Init


    //#region Loaders
    const LoadList = async() => {
        await Req("api/Manutencoes/Danos/0/0/0", "POST", {id: props.Id}, user.token).then(data => {
            setList(data.list);
        }).catch(err => {

        });
    }
    //#endregion Loaders


    //#region Handlers
    const HandleSelect = (info) => {
        props.OnSelect({info: info,  dd: Id, tag: 'Danos ' + Tag});
    }
    //#endregion Handlers


    return (
    <>
    <div className="content m-0">
        <div className="d-flex px-3">
            <div>
                <h5 className="pb-1 pt-3 font-13">Últimos danos relatados </h5>
            </div>
            <div className="ms-auto">
                <h5 className="ps-3 pb-1 pt-3 font-13 color-highlight"></h5>
            </div>
        </div>
        <div className="divider mb-0 mt-2"></div>
            <div className="list-group list-custom list-group-m rounded-l">
                

                {list.map((item, i) => (

                    <a key={'dano_'+i} href="#" data-bs-toggle="offcanvas" data-bs-target="#menu-information" className="list-group-item border-top-0 border-start-0 border-end-0 pointer" onClick={() => HandleSelect(item.info)}>
                        <img src="/images/pictures/ar.png" width="40" className="me-2 rounded-s" />
                        <i className={"bi bi-circle-fill font-10 ps-4 ms-2 mt-3 pt-1 position-absolute " + (item.status>0?'color-green-dark':'color-red-dark')}></i>
                        <div className="ps-1">
                            <strong className="font-600">{moment(item.data_cadastro).format("DD/MM/YYYY")}</strong>
                            <span className="opacity-90">{tipos[item.tipo]}</span>
                        </div>
                        <p className={'font-11 font-500 text-end ms-auto line-height-xs align-self-start pt-2 ' + (item.status>0?'color-green-dark':'color-red-dark')}>
                            Danificado
                        </p>
                    </a>    
                ))}    
            
        </div>
    </div>
    </>
    )
}