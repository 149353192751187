import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';



export default function Footer() {

    return (
        <div id="footer-bar" className="footer-bar footer-bar-detached">
            <a href="/Login"><i className="bi bi-x-circle font-17"></i><span>Sair</span></a>
            <a href="/Home" className="active-nav"><i className="bi bi-house-fill font-16"></i><span>Home</span></a>
            <a href="#" data-bs-toggle="offcanvas" data-bs-target="#menu-main"><i className="bi bi-list"></i><span>Menu</span></a>
        </div>
    )
}