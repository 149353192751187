import React, { useState, useContext, useCallback, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { Scanner } from '@yudiel/react-qr-scanner';
import './index.css';

export const QRCode = forwardRef((props, ref) => {
    const [qrshow, setQrshow] = useState(false);


    useImperativeHandle(ref, (url, args, token) => ({
        async Show(url, args, token = "") { return Show(url, args, token) }
    }));


    const Show = (r = true) => {
        setQrshow(r);
    }


    return (
        <div className="QRCode">
            {qrshow ? (<Scanner onScan={
                (result) => { 
                    Show(false);
                    props.onScan(result[0].rawValue);
                }
            } />) :(<></>) }
            
        </div>
    )
});