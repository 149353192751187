import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Context } from '../../..//Context/AuthContext';
import './index.css';


export default function Menu() {
    const component_name = "admin/menu";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);


    return (
      
            <div id="menu-main" data-menu-active="nav-homes" _data-menu-load="menu-main.html" style={{width:'280px'}} className="offcanvas offcanvas-start offcanvas-detached rounded-m">


                <div className="card card-style bg-23 mb-3 rounded-m mt-3" data-card-height="150">
                    <div className="card-top m-3">
                        <a href="#" data-bs-dismiss="offcanvas"
                            className="icon icon-xs bg-theme rounded-s color-theme float-end"><i
                                className="bi bi-caret-left-fill"></i></a>
                    </div>
                    <div className="card-bottom p-3">
                        <h1 className="color-white font-20 font-700 mb-n2">E-Cliente</h1>
                        <p className="color-white font-12 opacity-70 mb-n1">Atendimento e Manutenções</p>
                    </div>
                    <div className="card-overlay bg-gradient-fade rounded-0"></div>
                </div>


                <div className="bg-theme mx-3 rounded-m shadow-m mt-3 mb-3">
                    <div className="d-flex px-2 pb-2 pt-2">
                        
                        <div className="ps-2 align-self-center">
                            <h5 className="truncate ps-1 mb-0 line-height-xs pt-1">{user?.nome}</h5>
                            <h6 className="truncate ps-1 mb-0 font-400 opacity-40">{user?.empresa}</h6>
                        </div>
                      
                    </div>
                </div>


                <span className="menu-divider">NAVEGAÇÃO</span>
                <div className="menu-list">
                    <div className="card card-style rounded-m p-3 py-2 mb-0">
                        <a href="/Home" id="nav-homes"><i className="gradient-blue shadow-bg shadow-bg-xs bi bi-house-fill"></i><span>Home</span><i className="bi bi-chevron-right"></i></a>
                        <a href="/Equipamentos" id="nav-comps"><i className="gradient-green shadow-bg shadow-bg-xs bi bi-wrench"></i><span>Equipamentos</span><i className="bi bi-chevron-right"></i></a>
                        <a href="/Ocorrencias" id="nav-comps"><i className="gradient-yellow shadow-bg shadow-bg-xs bi bi-alarm"></i><span>Ocorrências</span><i className="bi bi-chevron-right"></i></a>
                    </div>
                </div>
                <span className="menu-divider mt-4">CONFIGURAÇÕES</span>
                <div className="menu-list">
                    <div className="card card-style rounded-m p-3 py-2 mb-0">
                        <a href="#" data-toggle-theme data-trigger-switch="switch-1">
                            <i className="gradient-dark shadow-bg shadow-bg-xs bi bi-moon-fill font-13"></i>
                            <span>Modo Escuro</span>
                            <div className="form-switch ios-switch switch-green switch-s me-2">
                                <input type="checkbox" data-toggle-theme className="ios-input" id="switch-1" />
                                <label className="custom-control-label" htmlFor="switch-1"></label>
                            </div>
                        </a>

                        <a href="/Login">
                            <i className="gradient-red shadow-bg shadow-bg-xs bi bi-x-circle"></i>
                            <span>Sair</span>
                            <i className="bi bi-chevron-right"></i>
                        </a>
                    </div>
                </div>
                
                <br /> <br />
                
                <p className="text-center mb-0 mt-n3 pb-3 font-9 text-uppercase font-600 color-theme">Copyright <i
                        className=" font-9 px-1 bi bi-at color-red-dark"></i> ConectarPlay <span
                        className="copyright-year"></span>.
                </p>
            </div>
     
    )
}