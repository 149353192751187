import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Context } from '../../../Context/AuthContext';
import { HubContext } from '../../../Context/HubContext.js';
import { Req, Request } from '../../../scripts/Request';
import { WaitObject } from '../../../scripts/Wait';
import { CacheDB } from '../../../scripts/CacheDB';
import InfiniteScroll from "react-infinite-scroll-component";
import { Currency } from '../../../scripts/StringUtils';
import { useNavigate } from 'react-router-dom';
import moment from 'moment/moment';
import ReactGA, { event } from 'react-ga';
import './index.css';
import { QRCode } from '../QRCode';


export default function Ocorrencias() {
    const component_name = "admin/equipamentos";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const { Hub } = useContext(HubContext);
    const [pageLoading, setPageLoading] = useState(false);
    const [list, setList] = useState([]);
    const [equipamentos, setEquipamentos] = useState([]);
    const [categorias, setCategorias] = useState([]);
    const [search, setSearch] = useState({ word: "", outros: false });
    const [chamado, setChamado] = useState({ cliente_id: "", categoria_id:"", equipamento_id:"", descricao:"" });
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [offset, setOffset] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const listRef = useRef([]);
    const pageRef = useRef({page: 0, offset: 0 });
    const totalRef = useRef(0);

    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);
    const searchRef = useRef({ word: "" });
    const _QRCodeRef = useRef();

    const navigate = useNavigate();



    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);

        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                WaitObject(user.nome).then(() => {
                    Init();
                });
            }
        }, 200);


        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    
                } catch(e) {}
            }
        }
    },[]);



    useEffect(() => {
        if(loading || pageLoading) {
            window.w_loadObj("body")
        } else {
            window.w_unloadAll();
        }
    }, [loading, pageLoading]);
    //#endregion Effects




    //#region Init
    const Init = async() => {
        Hub.Exec("HEADER_TITLE", "Ocorrências")

        /*try {
            document.removeEventListener("scrollend");
        } catch(e) {

        }

        await CacheDB.Initialize().then(async(result) => {
            
        }).catch((err) => { 
            console.log(err);
        });

        let keep = await CacheDB.Get("ocorrencias_keep");

        if(!keep || keep !== 1) {
            await CacheDB.Remove("ocorrencias_list");
        }*/

        _HandlerSearchChange();


       /* document.addEventListener("scrollend", (event) => {
            CacheDB.Set("ocorrencias_y", window.scrollY);
        });

        await CacheDB.Remove("ocorrencias_keep");*/

    }
    //#endregion Init



    //#region Loaders
    const LoadEquipamentos = async() => {
        await Req("api/Ocorrencias/Equipamentos", "POST", {}, user.token).then(data => {
            setEquipamentos(data);
        }).catch(err => {

        });
    }


    const LoadCategorias = async() => {
        await Req("api/Ocorrencias/Categorias", "POST", {}, user.token).then(data => {
            setCategorias(data);
        }).catch(err => {

        });
    }
    //#endregion Loaders



    //#region Handlers
    const _HandleChange = (event) => {
        setSearch(prev => prev = {...prev, [event.target.id]: (event.target.type==='checkbox'?event.target.checked:event.target.value)});
        searchRef.current = {...searchRef.current, [event.target.id]: (event.target.type==='checkbox'?event.target.checked:event.target.value)};

        
    }


    const _HandleClear = (event) => {
        setSearch(prev => prev = {word: ""});
        searchRef.current = {word: ""};
        _HandlerSearchChange(event);
    }


    const _HandleBuscar = async(event) => {
        setPage(0);
        setOffset(0);
        pageRef.current = { page: 0, offset: 0 };
        //CacheDB.Set("ocorrencias_y", 0);
        
        listRef.current = [];
        setList(listRef.current);
        setPageLoading(true);
        setHasMore(true);


        Promise.resolve(new Request().Run("api/Ocorrencias/List/" + pageRef.current.page + "/50/" + pageRef.current.offset, "POST", searchRef.current, user.token))
            .then((data) => {
                setTotal(data.total);
                totalRef.current = data.total;
                let _list = [...data.list];
                listRef.current = listRef.current.concat(_list);
                /*CacheDB.JSet("ocorrencias_list", listRef.current);
                CacheDB.Set("ocorrencias_total", data.total);
                CacheDB.Set("ocorrencias_page", 0);
                CacheDB.Set("ocorrencias_offset", 0);*/

                setList(listRef.current);
                setPageLoading(false);
                if (listRef.current.length >= totalRef.current) {
                    setHasMore(false);
                } 
            });
    }


    const _HandlerSearchChange = async(event) => {
        /*let data_cache = await CacheDB.JGet("ocorrencias_list");
        if(data_cache && Array.isArray(data_cache)){
            listRef.current = data_cache;
            let total_cache = await CacheDB.Get("ocorrencias_total");
            let page_cache = await CacheDB.Get("ocorrencias_page");
            let offset_cache = await CacheDB.Get("ocorrencias_offset");
            let y_cache = await CacheDB.Get("ocorrenciass_y");
            totalRef.current = total_cache;
            pageRef.current.page = page_cache;
            pageRef.current.offset = offset_cache;
            setPage(pageRef.current.page);
            setOffset(pageRef.current.offset);
            setList(listRef.current);
            setTimeout(() => {
                window.scrollTo({ top:y_cache, left:0, behavior: "instant"});
            }, 100);
        } else {
            _HandleBuscar();
        }

        setPageLoading(false);*/

        _HandleBuscar();
    }


    const _fetchMoreData = () => {
        try {
            if (listRef.current.length >= totalRef.current) {
                setHasMore(false);
                return;
            }

            pageRef.current.page++;
            pageRef.current.offset++;
            setPage(pageRef.current.page);
            setOffset(pageRef.current.offset);
            setPageLoading(true);

            Promise.resolve(new Request().Run("api/Ocorrencias/List/" + pageRef.current.page + "/50/" + pageRef.current.offset, "POST", searchRef.current, user.token))
                .then((data) => {
                    let _list = [...data.list];
                    listRef.current = listRef.current.concat(_list);
                    /*CacheDB.JSet("ocorrencias_list", listRef.current);
                    CacheDB.Set("ocorrencias_page", pageRef.current.page);
                    CacheDB.Set("ocorrencias_offset", pageRef.current.offset);*/
                    setList(listRef.current);
                    setPageLoading(false);
                });
        } catch (e) { }
    }


    const _HandleQRCodeClick = () => {
        _QRCodeRef.current.Show();
        
    
    }


    const _HandlerScan = value => {
        setSearch(prev => prev = {...prev, word: value});
        searchRef.current = {...searchRef.current, word: value};

        _HandleBuscar();

        let myOffcanvas = document.getElementById('menu-login');
        let bsOffcanvas = new window.bootstrap.Offcanvas(myOffcanvas);
        bsOffcanvas.show();


        setTimeout(function () {
            bsOffcanvas.hide();

           window.$('.offcanvas-backdrop').remove();
        }, 200);
       

        //window.$('.offcanvas').offcanvas('hide');
    }


    const _HandleDescricaoChange = (event) => {
        setChamado({...chamado, descricao: event.target.value})
    }


    const _HandleSave = async(event) => {
        if(chamado.categoria_id == "" || chamado.equipamento_id == "" || chamado.descricao.length < 4) {
            window.swal("Alerta", "Preencha todos os campos corretamente", "error");
            return;    
        }


        await Req("api/Ocorrencias/Save", "POST", chamado, user.token).then(data => {
            window.swal("Sucesso", "Chamado aberto com sucesso!", "success");
            _HandleBuscar();
        }).catch(err => {
            window.swal("Alerta", "Ocorreu um erro, tente novamente mais tarde", "error");
        });
    }


    const _HandleOpen = () => {
        LoadEquipamentos();
        LoadCategorias();
    }


    const _HandleSelectChange = (event) => {
        setChamado({...chamado, [event.target.id]: event.target.value})
    }
    //#endregion Handlers


    //#region Filters
    const statusColor = (id) => {
        switch (id) {
            case 0:
                return 'color-red-dark';
                break;
            case 1:
                return 'color-blue-dark';
                break;
            case 2:
                return 'color-yellow-dark';
                break;
            case 3:
                return 'color-green-dark';
                break;
        }
    }

    const statusLabel = (id) => {
        switch (id) {
            case 0:
                return 'Pendente';
                break;
            case 1:
                return 'Respondido';
                break;
            case 2:
                return 'Aguardando';
                break;
            case 3:
                return 'Finalizado';
                break;
        }
    }
    //#endregion Filters



    return (
        <>
        <div className="page-content header-clear-medium Ocorrencias">
            <div className="card card-style">
                <div className="content m-2 p-1">
                    <div className="d-flex">

                        <div className="align-self-center ps-1">
                            <div>
                                <input type="text" className="form-control search" id="word" value={search.word} onChange={_HandleChange}/>
                            </div>
                        </div>

                        
                        <div className="align-self-center ms-auto">
                            <a className="pointer" onClick={_HandleBuscar}>
                                <span className="icon icon-xs rounded-s gradient-highlight shadow-bg shadow-bg-xs">
                                    <i className="bi bi-search font-16 color-white"></i>
                                </span>
                            </a> &nbsp;

                            <a className="pointer" data-bs-toggle="offcanvas" data-bs-target="#menu-login" onClick={_HandleQRCodeClick}>
                                <span className="icon icon-xs rounded-s gradient-green shadow-bg shadow-bg-xs">
                                <i className="bi bi-qr-code-scan color-white font-16"></i>
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

           <center><a target="_blank" className="default-link btn btn-m rounded-s gradient-highlight shadow-bg shadow-bg-s px-5 mb-0 mt-2" data-bs-toggle="offcanvas" data-bs-target="#ocorrencia-create" onClick={_HandleOpen}>Novo Chamado</a></center>
         
            <br />
            

            <div className="card card-style">
                <div className="content m-0">
                    <div className="d-flex px-3">
                        <div>
                            <h5 className="pb-1 pt-3 font-13">Lista de Ocorrências</h5>
                        </div>
                        <div className="ms-auto">
                            <h5 className="ps-3 pb-1 pt-3 font-13 color-highlight">{list.length} Ocorrências encontradas</h5>
                        </div>
                    </div>
                    <div className="divider mb-0 mt-2"></div>
                    <div className="list-group list-custom list-group-m rounded-l">
                        <InfiniteScroll
                            dataLength={list.length}
                            next={_fetchMoreData}
                            hasMore={hasMore}
                            loader={
                                <p style={{ textAlign: "center", padding:'25px' }}>
                                    <b>Carregando...</b>
                                </p>
                            }
                            style={{ display: 'flex', overflow: 'hidden', flexDirection: 'column' }}
                            endMessage={
                                <p style={{ textAlign: "center", padding:'25px' }}>
                                    <b>Não há mais dados</b>
                                </p>
                            }>    

                            {list.map((item, i) => (
                                
                                <a key={'ocor_'+i} href={'/Ocorrencia/'+item.chamado_id} className="list-group-item border-top-0 border-start-0 border-end-0 pointer">
                                    <img src="/images/pictures/ocorrencia.png" width="40" className="me-2 rounded-s" />
                                    <i className={"bi bi-circle-fill font-10 ps-4 ms-2 mt-3 pt-1 position-absolute " + statusColor(item.status)}></i>
                                    <div className="ps-1">
                                        <strong className="font-600">{item.tag}</strong>
                                        <span className="opacity-90">{moment(item.data_cadastro).format("DD/MM/YYYY")} - {item.categoria}</span>
                                    </div>
                                    <p className={'font-11 font-500 text-end ms-auto line-height-xs align-self-start pt-2 ' + statusColor(item.status)}>
                                        {statusLabel(item.status)}
                                    </p>
                                </a>


                            ))}    
                        </InfiniteScroll>


                       
                        

                    </div>
                </div>
            </div>
        </div>

        <br />

        <div className="offcanvas offcanvas-bottom rounded-m offcanvas-detached offcanvas-99 bg-theme" id="menu-login">
            <div className="content">
                <QRCode ref={_QRCodeRef} onScan={_HandlerScan}/>
            </div>
        </div>


        <div className="offcanvas offcanvas-bottom rounded-m offcanvas-detached offcanvas-99" id="ocorrencia-create">
            <div className="gradient-blue px-3 py-3">
                <div className="d-flex mt-1">
                    <div className="align-self-center">
                        <i className="bi bi-info-circle-fill font-22 pe-2 scale-box color-white"></i>
                    </div>
                    <div className="align-self-center">
                        <h2 className="font-800 color-white mb-0">Abrir Chamado</h2>
                    </div>

                    
                </div>
               
            
                <label>Categoria</label>               
                <select id="categoria_id" class="form-control" onChange={_HandleSelectChange}>
                    <option value="">Selecione uma categoria</option>
                    {categorias && Array.isArray(categorias) && categorias.length > 0 && categorias.map((item, i) => (
                        <option key={'cat_'+i} value={item.id}>{item.name}</option>
                    ))}
                </select>

                <label>Equipamento</label>    
                <select id="equipamento_id" class="form-control" onChange={_HandleSelectChange}>
                    <option value="">Selecione um equipamento</option>
                    {equipamentos && Array.isArray(equipamentos) && equipamentos.length > 0 && equipamentos.map((item, i) => (
                        <option key={'equipo_'+i} value={item.id}>{item.name}</option>
                    ))}
                </select>
                  

                <p className="color-white opacity-80 pt-2">
                    <textarea className='interacao' id="descricao" value={chamado.descricao} onChange={_HandleDescricaoChange}>{chamado.descricao}</textarea>
                </p>
                <center>
                <a href="#" data-bs-dismiss="offcanvas" className="default-link btn btn-s gradient-green color-white" onClick={_HandleSave}>Salvar Chamado</a>
                &nbsp;<a href="#" data-bs-dismiss="offcanvas" className="default-link btn btn-s gradient-red color-white">Fechar</a>
                </center>
            </div>
        </div>
    </>
    )
}