import React, { useContext, useState, useEffect, useRef, useCallback, useMemo } from 'react';
import $ from 'jquery';
import { Context } from '../../Context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { Request } from '../../scripts/Request';
import { WaitObject } from '../../scripts/Wait';
import { GetServer } from '../../scripts/Loadbalancer';
import './Login.css';
import ReactGA from 'react-ga';


const Login = () => {
    const { loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [state, setState] = useState({
        login: "",
        password: "",
        code2fa: "",
        remember: false
    });
    const [pageLoading, setPageLoading] = useState(false);
    const [pwdState, setPwdState] = useState({ message: "", color: "#FFFFFF" });
    const [step, setStep] = useState(1);
    const [recoverStep, setRecoverStep] = useState(1);
    const [qr2fa, setQr2fa] = useState("");


    const [recover, setRecover] = useState({
        email: "",
        code: "",
        senha: "",
        resenha: "",
        token: ""
    });

    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    const loadedRef = useRef(false);
    const navigate = useNavigate();




    //#region Effects
    useEffect(() => {
        GetServer();

        if(!loadedRef.current) {
            loadedRef.current = true;
            ReactGA.pageview(window.location.pathname + window.location.search);
            Init();
        }
  
    }, []);


    useEffect(() => {
        if(loading || pageLoading) {
            window.w_loadObj("body")
        } else {
            window.w_unloadAll();
        }
     }, [loading, pageLoading]);

    //#endregion Effects


     //#region Core
     const Init = () => {
        const _userpwd = localStorage.getItem('userpwd');
        
        if (_userpwd) {
            let _u = JSON.parse(_userpwd);
            //console.log(_u);
            setState(_u);
        } else {
            localStorage.removeItem('userpwd');
        }
    };
    //#endregion Core


    //#region Loaders

    //#endregion Loaders


    //#region Handlers
    const _HandleChange = (e) => {
        const { id, value, type, checked } = e.target;
        //console.log(id, value);

        if(type === "checkbox") {
            setState(prevState => ({ ...prevState, [id]: checked }));
        } else {
            setState(prevState => ({ ...prevState, [id]: value }));
        }
    }


    const _handleLogin = async(event) => {
        event.preventDefault();
        let _state = Object.assign({}, state);
        let _userpwd = localStorage.getItem('userpwd');

        setState({ login: _state.login, password: _state.password, remember: _state.remember  });

        if(_state.login === "" || _state.password === "") { 
            window.swal("Alerta", "Preencha os campos corretamente!", "error");
            return;
        }


        if(_state.remember) {
            localStorage.setItem('userpwd', JSON.stringify({ login: _state.login, password: _state.password, remember: _state.remember }));
        } else {
            localStorage.removeItem('userpwd');
        }

        _userpwd = localStorage.getItem('userpwd');
        await handleLogin(_state);
    };


  




    //#endregion Handlers





    return (
        <div>
        <div className="header-bar header-fixed header-app header-bar-detached">
            &nbsp;&nbsp;&nbsp;
            <a href="#" className="header-title color-theme font-13">Área de login</a>
            <a href="#" className="show-on-theme-light" data-toggle-theme=""><i className="bi bi-moon-fill font-13"></i></a>
            <a href="#" className="show-on-theme-dark" data-toggle-theme=""><i className="bi bi-lightbulb-fill color-yellow-dark font-13"></i></a>
        </div>

 

    
  


        <div id="page">
            <div className="page-content mb-0 pb-0">
                <div className="card card-style mb-0 bg-transparent shadow-0 bg-3 mx-0 rounded-0" data-card-height="cover">
                    <div className="card-center">
                        <div className="card card-style">
                            <div className="content">
                                <center>
                                <img src="/images/pictures/logo.png" />
                                </center>
                                <div className="form-custom form-label form-icon mb-3">
                                    <i className="bi bi-person-circle font-14"></i>
                                    <input type="text" className="form-control rounded-xs" id="login" placeholder="Digite seu login" value={state.login} onChange={_HandleChange}/>
                                    <label htmlFor="login" className="color-theme">SEU LOGIN</label>
                                    
                                </div>
                                <div className="form-custom form-label form-icon mb-3">
                                    <i className="bi bi-asterisk font-12"></i>
                                    <input type="password" className="form-control rounded-xs" id="password" placeholder="Digite sua senha" value={state.password} onChange={_HandleChange}/>
                                    <label htmlFor="password" className="color-theme">SUA SENHA</label>
                                    
                                </div>

                                <div className="remember_div">
                                    <input className="form-check-input" type="checkbox" id="remember" checked={state.remember} onClick={_HandleChange}/>
                                    <label className="" htmlFor="remember"> Lembrar senha </label>
                                </div>

                             
                                <a className='btn rounded-sm btn-m gradient-green text-uppercase font-700 mt-4 mb-3 btn-full shadow-bg shadow-bg-s pointer' onClick={_handleLogin}>ENTRAR</a>
                                {/*<div className="d-flex">
                                    <div>
                                        <a href="page-forgot-2.html" className="color-theme opacity-30 font-12">Recover
                                            Account</a>
                                    </div>
                                    <div className="ms-auto">
                                        <a href="page-register-2.html" className="color-theme opacity-30 font-12">Create
                                            Account</a>
                                    </div>
                                </div>*/}


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        </div>
    )
};

export default Login;