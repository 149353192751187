import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { HubContext } from '../../Context/HubContext.js';


export default function Header() {
    const { Hub } = useContext(HubContext);
    const [title, setTitle] = useState("ECliente ConectarPlay");

    useEffect(() => {
        Init();
    }, [])


    const Init = () => {
        Hub.Set("HEADER_TITLE", value => {
            if(title!=="") setTitle(value);
        });
    }


    const _HandleGoBack = () => {
        window.history.back();
    }


    return (
        <div className="header-bar header-fixed header-app header-bar-detached">
            {title === "ECliente ConectarPlay" || title === "Equipamentos" ? 
            (<a data-bs-toggle="offcanvas" data-bs-target="#menu-main" href="#"><i className="bi bi-list color-theme"></i></a>) : 
            (<a data-back-button="" className="pointer" onClick={_HandleGoBack}><i className="bi bi-caret-left-fill font-11 color-theme ps-2"></i></a>)}
            
            

            <a href="#" className="header-title color-theme">{title}</a>
            {/*<a href="#" data-bs-toggle="offcanvas" data-bs-target="#menu-color"><i className="bi bi-palette-fill font-13 color-highlight"></i></a>
            <a href="#" data-bs-toggle="offcanvas" data-bs-target="#menu-bell"><em className="badge bg-highlight ms-1">3</em><i className="font-14 bi bi-bell-fill"></i></a>*/}
            <a href="#" className="show-on-theme-light" data-toggle-theme=""><i className="bi bi-moon-fill font-13"></i></a>
            <a href="#" className="show-on-theme-dark" data-toggle-theme=""><i className="bi bi-lightbulb-fill color-yellow-dark font-13"></i></a>
        </div>
    )
}