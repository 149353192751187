import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Context } from '../../../Context/AuthContext';
import { HubContext } from '../../../Context/HubContext.js';
import { Req, Request } from '../../../scripts/Request';
import { WaitObject } from '../../../scripts/Wait';
import { CacheDB } from '../../../scripts/CacheDB';
import InfiniteScroll from "react-infinite-scroll-component";
import { Currency } from '../../../scripts/StringUtils';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment/moment';
import ReactGA from 'react-ga';
import './index.css';


export default function Ocorrencia() {
    const component_name = "admin/ocorrencia";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const { Hub } = useContext(HubContext);
    const [pageLoading, setPageLoading] = useState(false);
    const [list, setList] = useState([]);
    const [info, setInfo] = useState({});
    const [interacao, setInteracao] = useState("");

    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);

    let { Id } = useParams();


    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);

        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                WaitObject(user.nome).then(() => {
                    Init();
                });
            }
        }, 200);


        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    
                } catch(e) {}
            }
        }
    },[]);



    useEffect(() => {
        if(loading || pageLoading) {
            window.w_loadObj("body")
        } else {
            window.w_unloadAll();
        }
    }, [loading, pageLoading]);
    //#endregion Effects



    //#region Init
    const Init = async() => {
        Hub.Exec("HEADER_TITLE", "Ocorrência")

        LoadOcorrencia();
    }
    //#endregion Init


    //#region LoadOcorrencia
    const LoadOcorrencia = async() => {
        await Req("api/Ocorrencias/Load?id=" + Id, "GET", "", user.token).then(data => {
            if(data && data?.info && data?.list) {
                setInfo(data.info);
                setList(data.list);
            }
        }).catch(err => {
            window.swal("Alerta", "Houve um problema, tente novamente mais tarde!", "error");
        })
    }
    //#endregion LoadOcorrencia


    //#region Handlers
    const _HandleInteracaoChange = (event) => {
        setInteracao(event.target.value)
    }


    const _HandleSave = async() => {
        await Req("api/Ocorrencias/SaveInteraction", "POST", {chamado_id: Id, from_id: user?.user_id, descricao: interacao, from_client: 1 }, user.token).then(data => {
            if(data.toString() === "loaded") {
                window.swal("Sucesso", "Interação com sucesso!", "success");
                LoadOcorrencia();
            } else {
                window.swal("Alerta", "Houve um problema, tente novamente mais tarde!", "error");
            }
        }).catch(err => {
            window.swal("Alerta", "Houve um problema, tente novamente mais tarde!", "error");
        })
    }


    const _HandleFinalizar = async() => {
        await Req("api/Ocorrencias/FinalizarChamado", "POST", {chamado_id: Id}, user.token).then(data => {
            if(data.toString() === "loaded") {
                window.swal("Sucesso", "Chamado finalizado com sucesso!", "success");
                LoadOcorrencia();
            } else {
                window.swal("Alerta", "Houve um problema, tente novamente mais tarde!", "error");
            }
        }).catch(err => {
            window.swal("Alerta", "Houve um problema, tente novamente mais tarde!", "error");
        })
    }


    const _HandleReabrir = async() => {
        await Req("api/Ocorrencias/ReabrirChamado", "POST", {chamado_id: Id}, user.token).then(data => {
            if(data.toString() === "loaded") {
                window.swal("Sucesso", "Chamado reaberto com sucesso!", "success");
                LoadOcorrencia();
            } else {
                window.swal("Alerta", "Houve um problema, tente novamente mais tarde!", "error");
            }
        }).catch(err => {
            window.swal("Alerta", "Houve um problema, tente novamente mais tarde!", "error");
        })
    }
    //#endregion Handlers



    //#region Filters
    const statusColor = (id) => {
        switch (id) {
            case 0:
                return 'color-green-dark';
                break;
            case 1:
                return 'color-blue-dark';
                break;
        }
    }

    const statusLabel = (id) => {
        switch (id) {
            case 1:
                return 'Pendente';
                break;
            case 0:
                return 'Respondido';
                break;
        }
    }
    //#endregion Filters


    return (
        <>
        <div className="page-content header-clear-medium Ocorrencia">
            <center>
            { info?.data_finalizado === "" && (<><a target="_blank" className="default-link btn btn-m rounded-s gradient-highlight shadow-bg shadow-bg-s px-5 mb-0 mt-2" data-bs-toggle="offcanvas" data-bs-target="#ocorrencia-information">Nova Interação</a></>)}
            { info?.data_finalizado && info?.data_finalizado !== "" && (<>&nbsp; <a target="_blank" className="default-link btn btn-m rounded-s gradient-green shadow-bg shadow-bg-s px-5 mb-0 mt-2" onClick={_HandleReabrir}>Reabrir Chamado</a></>)}
            { info?.data_finalizado === "" && (<>&nbsp; <a target="_blank" className="default-link btn btn-m rounded-s gradient-red shadow-bg shadow-bg-s px-5 mb-0 mt-2" onClick={_HandleFinalizar}>Finalizar Chamado</a></>)}
            </center>
            <br />

            <div className="card card-style">
                <div className="content m-0">
                    <div className="list-group list-custom list-group-m rounded-l">
                        <div className="list-group-item border-top-0 border-start-0 border-end-0 pointer">
                            <b>Nome:</b>&nbsp;{info?.nome} - &nbsp; <b style={{color:'green'}}>{info?.categoria}</b>
                        </div>

                        <div className="list-group-item border-top-0 border-start-0 border-end-0 pointer">
                            <b>Equipamento:</b> &nbsp;{info?.tag} -&nbsp;{info?.modelo} 
                        </div>

                        <div className="list-group-item border-top-0 border-start-0 border-end-0 pointer">
                            <b>Descrição:</b> &nbsp;{info?.descricao}
                        </div>

                        
                        <div className="list-group-item border-top-0 border-start-0 border-end-0 pointer">
                        <b>Aberto em:</b>&nbsp;{moment(info?.data_cadastro).format('DD/MM/YYYY')} -&nbsp;    
                        { info?.data_finalizado && info?.data_finalizado !== "" && (<><b>Finalizado em:</b>&nbsp;{moment(info?.data_finalizado).format('DD/MM/YYYY')}</>)}
                        </div>
                        
                    </div>
                </div>
            </div>
            

            <div className="card card-style">
                <div className="content m-0">
                    <div className="d-flex px-3">
                        <div>
                            <h5 className="pb-1 pt-3 font-13">Lista de Interações</h5>
                        </div>
                        <div className="ms-auto">
                            <h5 className="ps-3 pb-1 pt-3 font-13 color-highlight">{list.length} Interações</h5>
                        </div>
                    </div>
                    <div className="divider mb-0 mt-2"></div>
                    <div className="list-group list-custom list-group-m rounded-l">
                            {
                            list.map((item, i) => (
                                
                                <a key={'interacao_'+i}  href={'https://conectarplay.com.br/services/Ocorrencia.aspx?q='+Id+'&nprint=mobile'} target="_blank" className="list-group-item border-top-0 border-start-0 border-end-0 pointer">
                                    <img src="/images/pictures/interacao.png" width="40" className="me-2 rounded-s" />
                                    <i className={"bi bi-circle-fill font-10 ps-4 ms-2 mt-3 pt-1 position-absolute " + statusColor(item.from_client)}></i>
                                    <div className="ps-1">
                                        <strong className="font-600 descricao">{item.descricao}</strong>
                                        <span className="opacity-90">{moment(item.data_cadastro).format("DD/MM/YYYY")} - {item.from_name}</span>
                                    </div>
                                    {/*<p className={'font-11 font-500 text-end ms-auto line-height-xs align-self-start pt-2 ' + statusColor(item.from_client)}>
                                        {statusLabel(item.from_client)}
                                    </p>*/}
                                </a>
                               
                            ))}  


                    </div>
                </div>
            </div>
        </div>


            <div className="offcanvas offcanvas-bottom rounded-m offcanvas-detached offcanvas-99" id="ocorrencia-information">
                <div className="gradient-blue px-3 py-3">
                    <div className="d-flex mt-1">
                        <div className="align-self-center">
                            <i className="bi bi-info-circle-fill font-22 pe-2 scale-box color-white"></i>
                        </div>
                        <div className="align-self-center">
                            <h2 className="font-800 color-white mb-0">Nova Interação</h2>
                        </div>

                       
                    </div>
                    <p className="color-white opacity-80 pt-2">
                        <textarea className='interacao' id="interacao" value={interacao} onChange={_HandleInteracaoChange}>{interacao}</textarea>
                    </p>
                    <center>
                    <a href="#" data-bs-dismiss="offcanvas" className="default-link btn btn-s gradient-green color-white" onClick={_HandleSave}>Salvar Interação</a>
                    &nbsp;<a href="#" data-bs-dismiss="offcanvas" className="default-link btn btn-s gradient-red color-white">Fechar</a>
                    </center>
                </div>
            </div>
        </>
    )
}