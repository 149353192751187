import React, { useState, useEffect, useContext, lazy } from 'react';
import { useLocation } from "react-router-dom";
import Footer from './components/Footer';
import Header from './components/Header';

import { Context } from './Context/AuthContext';
import Menu from './components/Admin/Menu';


export default function Layout({ children }) {
    const [isLogin, setIsLogin] = useState(true);
    const location = useLocation()
    const { user, loading, authenticated } = useContext(Context);


     //#region Effects
     useEffect(() => {
        console.log(window.location.href);
        setIsLogin(location.pathname.toLocaleLowerCase().indexOf("login") > -1 
        || location.pathname === "/" 
        || window.location.href.toLocaleLowerCase().indexOf(".com.br/cadastro") > -1);


        setTimeout(() => {
            window.InitCustom();
        }, 300);
    }, [location.pathname]);

    //#endregion Effects

    return (
        <React.Fragment>
            {
                !isLogin && authenticated && user &&  user.nivel < 7 ? (
                    
                    <div id="page">
                        <Header />
                        <Menu />
                        {children}
                        <Footer />
                    </div>
                    
                    
                    ) : (<div>{children}</div>) 
            }
          
        </React.Fragment>
    )
}