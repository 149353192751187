import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Context } from '../../../Context/AuthContext';
import { HubContext } from '../../../Context/HubContext.js';
import { Req, Request } from '../../../scripts/Request';
import { WaitObject } from '../../../scripts/Wait';
import { CacheDB } from '../../../scripts/CacheDB';
import InfiniteScroll from "react-infinite-scroll-component";
import { Currency } from '../../../scripts/StringUtils';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment/moment';
import ReactGA from 'react-ga';
import './Manutencoes.css';


const tipos = [
    "Manutenção Preventiva",
    "Manutenção Corretiva",
    "",
    "Instalação",
    "PMOC",
];



export default function Geral(props) {
    const component_name = "admin/manutencoes_geral";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const { Hub } = useContext(HubContext);
    const [pageLoading, setPageLoading] = useState(false);
    const [list, setList] = useState([]);


    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);



    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);

        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                WaitObject(user.nome).then(() => {
                    Init();
                });
            }
        }, 200);


        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    
                } catch(e) {}
            }
        }
    },[]);



    useEffect(() => {
        if(loading || pageLoading) {
            window.w_loadObj("body")
        } else {
            window.w_unloadAll();
        }
    }, [loading, pageLoading]);
    //#endregion Effects



    //#region Init
    const Init = async() => {
        Hub.Exec("HEADER_TITLE", "Manutenções - " + props.Tag);

        LoadList();
     }
    //#endregion Init


    //#region Loaders
    const LoadList = async() => {
        await Req("api/Manutencoes/List/0/0/0", "POST", {id: props.Id}, user.token).then(data => {
            setList(data.list);
        }).catch(err => {

        });
    }
    //#endregion Loaders


    //#region Handlers
    const HandleSelect = (item) => {
        //console.log(item)
        //props.OnSelect(info);
        if(parseInt(item.tipo) === 6) {
            props.OnSelect({info: item.hasfotos, dd: props.Id, tag: 'Lavagem ' + props.Tag});
        } else  if(parseInt(item.tipo) === 7) {
            props.OnSelect({info: item.hasfotos, dd: props.Id, tag: 'Dano ' + props.Tag});
        } else {

        }
    }


    const ProcLink = (item) => {
        if(parseInt(props.Outros) === 1) {
            return 'https://conectarplay.com.br/print/equipamentos_manut_outro_app.html?ids=' + item.mid + "&cli=" + user?.nome + '&tag=' + props.Tag;
        } else if(item.tipo === 3) {
            return 'https://conectarplay.com.br/print/equipamentos_install_app.html?ids=' + item.mid + "&cli=" + user?.nome + '&tag=' + props.Tag;
        } else if(item.tipo === 0 || item.tipo === 1) {
            return 'https://conectarplay.com.br/print/equipamentos_manut_app.html?ids=' + item.mid + "&cli=" + user?.nome + '&tag=' + props.Tag;
        } else if(item.tipo === 6 || item.tipo === 7) {
            return '#';
        } else if(item.tipo === 4) {
            return 'https://conectarplay.com.br/services/PmocPrintMobile.aspx?id=' + item.mid + '&data=' + moment(item.data_cadastro).format('YYYY-MM-DD');
        } else {
            return 'javascript:alert("Link indisponível")';
        }
    }


    const ProcTarget = (item) => {
        if(item.tipo === 3) {
            return '_blank';
        } else if(item.tipo === 0 || item.tipo === 1) {
            return '_blank';
        } else if(item.tipo === 6 || item.tipo === 7) {
            return '';
        } else if(item.tipo === 4) {
            return '_blank';
        } else {
            return '_self';
        }
    }
    //#endregion Handlers


    //#region Filters
    const tipoFilter = (id) => {
        switch(parseInt(id)) {
            case 0:
                return 'Manutenção Preventiva';
                break;
            case 1:
                return 'Manutenção Corretiva';
                break;
            case 2:
                return '';
                break;
            case 3:
                return 'Instalação';
                break;
            case 4:
                return 'PMOC';
                break;
            case 5:
                return 'Ocorrência';
                break;
            case 6:
                return 'Lavagem';
                break;
            case 7:
                return 'Dano';
                break;
        }
    }


    const imgFilter = (id) => {
        switch(parseInt(id)) {
            case 0:
                return 'ar.png';
                break;
            case 1:
                return 'ar.png';
                break;
            case 2:
                return 'ar.png';
                break;
            case 3:
                return 'ar.png';
                break;
            case 4:
                return 'pmoc.png';
                break;
            case 5:
                return 'ocorrencia.png';
                break;
            case 6:
                return 'lavagem.png';
                break;
            case 7:
                return 'dano.png';
                break;
        }
    }


    const hasfotosFilter = (tipo, id) => {
        switch(parseInt(tipo)) {
            case 0:
                if(parseInt(id) === 0) {
                    return "Sem fotos";
                } else {
                    return "Tem fotos";
                }
                break;
            case 1:
                if(parseInt(id) === 0) {
                    return "Sem fotos";
                } else {
                    return "Tem fotos";
                }
                break;
            case 2:
                return '';
                break;
            case 3:
                return '';
                break;
            case 4:
                return '';
                break;
            case 5:
                return '';
                break;
            case 6:
                return '';
                break;
            case 7:
                return '';
                break;
        }
    }
    //#endregion Filters


    return (
    <>
    <div className="content m-0">
        <div className="d-flex px-3">
            <div>
                <h5 className="pb-1 pt-3 font-13">Instalação / Manutenções </h5>
            </div>
            <div className="ms-auto">
                <h5 className="ps-3 pb-1 pt-3 font-13 color-highlight"></h5>
            </div>
        </div>
        <div className="divider mb-0 mt-2"></div>
            <div className="list-group list-custom list-group-m rounded-l">
                

                {list.map((item, i) => (

                    <a key={'equipo_'+i} href={ProcLink(item)} target={ProcTarget(item)} data-bs-toggle={item.tipo===6||item.tipo===7?"offcanvas":""} data-bs-target="#menu-information" className="list-group-item border-top-0 border-start-0 border-end-0 pointer" onClick={() => HandleSelect(item)}>
                        <img src={"/images/pictures/"+ imgFilter(item.tipo)} width="40" className="me-2 rounded-s" />
                        <i className={"bi bi-circle-fill font-10 ps-4 ms-2 mt-3 pt-1 position-absolute " + (item.hasfotos>0?'color-green-dark':'color-red-dark')}></i>
                        <div className="ps-1">
                            <strong className="font-600">{moment(item.data_cadastro).format("DD/MM/YYYY")}</strong>
                            <span className="opacity-90">{tipoFilter(item.tipo)}</span>
                        </div>
                        <p className={'font-11 font-500 text-end ms-auto line-height-xs align-self-start pt-2 ' + (item.hasfotos>0?'color-green-dark':'color-red-dark')}>
                            {hasfotosFilter(item.tipo, item.hasfotos)}
                        </p>
                    </a>    
                ))}    
            
        </div>
    </div>

    
</>
    )
}