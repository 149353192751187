import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Context } from '../../../Context/AuthContext';
import { HubContext } from '../../../Context/HubContext.js';
import { Request } from '../../../scripts/Request';
import { WaitObject } from '../../../scripts/Wait';
import { CacheDB } from '../../../scripts/CacheDB';
import InfiniteScroll from "react-infinite-scroll-component";
import { Currency } from '../../../scripts/StringUtils';
import { useNavigate } from 'react-router-dom';
import moment from 'moment/moment';
import ReactGA from 'react-ga';
import './index.css';
import { QRCode } from '../QRCode';


export default function Equipamentos() {
    const component_name = "admin/equipamentos";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const { Hub } = useContext(HubContext);
    const [pageLoading, setPageLoading] = useState(false);
    const [list, setList] = useState([]);
    const [search, setSearch] = useState({ word: "", outros: false });
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [offset, setOffset] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const listRef = useRef([]);
    const pageRef = useRef({page: 0, offset: 0 });
    const totalRef = useRef(0);

    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);
    const searchRef = useRef({ word: "" });
    const _QRCodeRef = useRef();

    const navigate = useNavigate();


    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);

        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                WaitObject(user.nome).then(() => {
                    Init();
                });
            }
        }, 200);


        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    
                } catch(e) {}
            }
        }
    },[]);



    useEffect(() => {
        if(loading || pageLoading) {
            window.w_loadObj("body")
        } else {
            window.w_unloadAll();
        }
    }, [loading, pageLoading]);
    //#endregion Effects


     //#region Init
     const Init = async() => {
        Hub.Exec("HEADER_TITLE", "Equipamentos")

        try {
            document.removeEventListener("scrollend");
        } catch(e) {

        }

        await CacheDB.Initialize().then(async(result) => {
            
        }).catch((err) => { 
            console.log(err);
        });

        let keep = await CacheDB.Get("equipamentos_keep");

        if(!keep || keep !== 1) {
            await CacheDB.Remove("equipamentos_list");
        }

        _HandlerSearchChange();


        document.addEventListener("scrollend", (event) => {
            CacheDB.Set("equipamentos_y", window.scrollY);
        });

        await CacheDB.Remove("equipamentos_keep");
     }
    //#endregion Init



    //#region Handlers
    const _HandleChange = (event) => {
        setSearch(prev => prev = {...prev, [event.target.id]: (event.target.type==='checkbox'?event.target.checked:event.target.value)});
        searchRef.current = {...searchRef.current, [event.target.id]: (event.target.type==='checkbox'?event.target.checked:event.target.value)};

        //console.log(event.target.id, event.target.value, event.target.checked);

        if(event.target.id === "outros") {
            _HandleBuscar();
        }
    }


    const _HandleClear = (event) => {
        setSearch(prev => prev = {word: "", outros: ""});
        searchRef.current = {word: "", outros: ""};
        _HandlerSearchChange(event);
    }


    const _HandleBuscar = async(event) => {
        setPage(0);
        setOffset(0);
        pageRef.current = { page: 0, offset: 0 };
        CacheDB.Set("equipamentos_y", 0);
        
        listRef.current = [];
        setList(listRef.current);
        setPageLoading(true);
        setHasMore(true);


        Promise.resolve(new Request().Run("api/Equipamentos/List" + (searchRef.current.outros?'Outros':'') +  "/" + pageRef.current.page + "/50/" + pageRef.current.offset, "POST", searchRef.current, user.token))
            .then((data) => {
                setTotal(data.total);
                totalRef.current = data.total;
                let _list = [...data.list];
                listRef.current = listRef.current.concat(_list);
                CacheDB.JSet("equipamentos_list", listRef.current);
                CacheDB.Set("equipamentos_total", data.total);
                CacheDB.Set("equipamentos_page", 0);
                CacheDB.Set("equipamentos_offset", 0);

                setList(listRef.current);
                setPageLoading(false);
                if (listRef.current.length >= totalRef.current) {
                    setHasMore(false);
                } 
            });
    }


    const _HandlerSearchChange = async(event) => {
        let data_cache = await CacheDB.JGet("equipamentos_list");
        if(data_cache && Array.isArray(data_cache)){
            listRef.current = data_cache;
            let total_cache = await CacheDB.Get("equipamentos_total");
            let page_cache = await CacheDB.Get("equipamentos_page");
            let offset_cache = await CacheDB.Get("equipamentos_offset");
            let y_cache = await CacheDB.Get("equipamentos_y");
            totalRef.current = total_cache;
            pageRef.current.page = page_cache;
            pageRef.current.offset = offset_cache;
            setPage(pageRef.current.page);
            setOffset(pageRef.current.offset);
            setList(listRef.current);
            setTimeout(() => {
                window.scrollTo({ top:y_cache, left:0, behavior: "instant"});
            }, 100);
        } else {
            _HandleBuscar();
        }

        setPageLoading(false);
    }


    const _fetchMoreData = () => {
        try {
            if (listRef.current.length >= totalRef.current) {
                setHasMore(false);
                return;
            }

            pageRef.current.page++;
            pageRef.current.offset++;
            setPage(pageRef.current.page);
            setOffset(pageRef.current.offset);
            setPageLoading(true);

            Promise.resolve(new Request().Run("api/Equipamentos/List" + (searchRef.current.outros?'Outros':'') +  "/" + pageRef.current.page + "/50/" + pageRef.current.offset, "POST", searchRef.current, user.token))
                .then((data) => {
                    let _list = [...data.list];
                    listRef.current = listRef.current.concat(_list);
                    CacheDB.JSet("equipamentos_list", listRef.current);
                    CacheDB.Set("equipamentos_page", pageRef.current.page);
                    CacheDB.Set("equipamentos_offset", pageRef.current.offset);
                    setList(listRef.current);
                    setPageLoading(false);
                });
        } catch (e) { }
    }


    const _HandleQRCodeClick = () => {
        _QRCodeRef.current.Show();
        
    
    }


    const _HandlerScan = value => {
        setSearch(prev => prev = {...prev, word: value});
        searchRef.current = {...searchRef.current, word: value};

        _HandleBuscar();

        let myOffcanvas = document.getElementById('menu-login');
        let bsOffcanvas = new window.bootstrap.Offcanvas(myOffcanvas);
        bsOffcanvas.show();


        setTimeout(function () {
            bsOffcanvas.hide();

           window.$('.offcanvas-backdrop').remove();
        }, 200);
       

        //window.$('.offcanvas').offcanvas('hide');
    }
    //#endregion Handlers


    return (
        <>
            <div className="page-content header-clear-medium Equipamentos">
                <div className="card card-style">
                    <div className="content m-2 p-1">
                        <div className="d-flex">

                            <div className="align-self-center ps-1">
                                <div>
                                    <input type="text" className="form-control search" id="word" value={search.word} onChange={_HandleChange}/>
                                </div>
                            </div>

                            
                            <div className="align-self-center ms-auto">
                                <a className="pointer" onClick={_HandleBuscar}>
                                    <span className="icon icon-xs rounded-s gradient-highlight shadow-bg shadow-bg-xs">
                                        <i className="bi bi-search font-16 color-white"></i>
                                    </span>
                                </a> &nbsp;

                                <a className="pointer" data-bs-toggle="offcanvas" data-bs-target="#menu-login" onClick={_HandleQRCodeClick}>
                                    <span className="icon icon-xs rounded-s gradient-green shadow-bg shadow-bg-xs">
                                    <i className="bi bi-qr-code-scan color-white font-16"></i>
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

             
                <div className="tipo" data-trigger-switch="outros">
                    <div className="ms-auto align-self-center">
                        <h6 className="mb-0 font-12">Listar outros equipamentos</h6>
                    </div>
                    <div className="ms-auto align-self-center">
                        <div className="form-switch ios-switch switch-blue switch-m">
                            <input type="checkbox" className="ios-input" id="outros" checked={search.outros} onClick={_HandleChange}/>
                            <label className="custom-control-label" htmlFor="outros"></label>
                        </div>
                    </div>
                </div>
                

                <div className="card card-style">
                    <div className="content m-0">
                        <div className="d-flex px-3">
                            <div>
                                <h5 className="pb-1 pt-3 font-13">Lista de Equipamentos</h5>
                            </div>
                            <div className="ms-auto">
                                <h5 className="ps-3 pb-1 pt-3 font-13 color-highlight">{totalRef.current} Equipamentos encontrados</h5>
                            </div>
                        </div>
                        <div className="divider mb-0 mt-2"></div>
                        <div className="list-group list-custom list-group-m rounded-l">
                            <InfiniteScroll
                                dataLength={list.length}
                                next={_fetchMoreData}
                                hasMore={hasMore}
                                loader={
                                    <p style={{ textAlign: "center", padding:'25px' }}>
                                        <b>Carregando...</b>
                                    </p>
                                }
                                style={{ display: 'flex', overflow: 'hidden', flexDirection: 'column' }}
                                endMessage={
                                    <p style={{ textAlign: "center", padding:'25px' }}>
                                        <b>Não há mais dados</b>
                                    </p>
                                }>    

                                {list.map((item, i) => (

                                    <a href={'/Manutencoes/' + item.id + '/' + item.tag + "/" + (search.outros?1:0)} key={'equipo_'+i} className="list-group-item border-top-0 border-start-0 border-end-0 pointer">
                                        <img src="/images/pictures/ar.png" width="40" className="me-2 rounded-s" />
                                        <i className={"bi bi-circle-fill font-10 ps-4 ms-2 mt-3 pt-1 position-absolute " + (item.vencido<0?'color-red-dark':'color-green-dark')}></i>
                                        <div className="ps-1">
                                            <strong className="font-600">{item.tag} {item.modelo}</strong>
                                            <span className="opacity-90">{item.capacidade} - {item.marca} - {item.local}</span>
                                        </div>
                                        <p className={'font-11 font-500 text-end ms-auto line-height-xs align-self-start pt-2 ' + (item.vencido<0?'color-red-dark':'color-green-dark')}>
                                            {item.vencido<0?'Vencido':'Regular'}
                                        </p>
                                    </a>    
                                ))}    
                            </InfiniteScroll>


                           
                            

                        </div>
                    </div>
                </div>
            </div>

            <br />

            <div className="offcanvas offcanvas-bottom rounded-m offcanvas-detached offcanvas-99 bg-theme" id="menu-login">
                <div className="content">
                    <QRCode ref={_QRCodeRef} onScan={_HandlerScan}/>
                </div>
            </div>
        </>
    )
}